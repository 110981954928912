import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	inject,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { dropdownAnimation } from '../../animations/dropdown.animation';

@Component({
	selector: 'ui-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	animations: [dropdownAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit {
	@Input() width: number = 0;
	@Input() top: number = 0;
	@Input() left: number | undefined = 0;
	@Input() right: number | undefined;
	@Input() triggerTop: number = 0;
	@Input() triggerRight: number = 0;
	@Input() template: TemplateRef<any> | null = null;
	@Input() id: string = '';

	@ViewChild('dropdown') dropdown: ElementRef | undefined;

	open: boolean = false;

	bottom: number | undefined;

	public cd: ChangeDetectorRef = inject(ChangeDetectorRef);
	public elementRef: ElementRef = inject(ElementRef);

	getStyles(): any {
		const ret: any = {};
		if (this.top) {
			ret['top'] = this.top + 'px';
		}
		if (this.left) {
			ret['left'] = this.left + 'px';
		}
		if (this.bottom) {
			ret['bottom'] = this.bottom + 'px';
		}
		if (this.width) {
			ret['width'] = this.width + 'px';
		}
		if (this.right) {
			ret['right'] = this.right + 'px';
		}

		return ret;
	}

	ngOnInit() {
		/**
		 * Needed to trigger the dropdown animation
		 */
		setTimeout(() => {
			this.open = true;
			this.cd.markForCheck();
			this.setPosition();
		});
	}

	close() {
		this.open = false;
		this.cd.markForCheck();
	}

	/** Waits for the animation to complete
	 * Then checks if the dropdown exceeds the page height
	 * If it does, then the dropdown will be placed above the trigger,
	 * also checks the width of the dropdown and the width of the .app element
	 * We do not use the window.innerWidth, as we have a max width of our app
	 */
	setPosition() {
		setTimeout(() => {
			if (this.dropdown) {
				const { bottom, height, right, width, left } =
					this.dropdown.nativeElement.getBoundingClientRect();
				if (window.innerHeight < bottom) {
					this.top = this.triggerTop - height + 4;
					this.cd.markForCheck();
				}

				const appElement = document.querySelector('.app');
				if (appElement) {
					const appWidth = appElement.getBoundingClientRect().width;
					// screen is larger than app
					if (appWidth < right) {
						this.left = this.triggerRight - width;
						this.cd.markForCheck();
					}
					if (appWidth < width) {
						this.width = appWidth - 32;
						this.left = left + 1;
						this.cd.markForCheck();
					}
				}
			}
		});
	}
}
