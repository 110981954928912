import { convertToUTCDate, generateMonth, groupByCalendarWeek } from '@agilox/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';

import { CalendarDate, CalendarWeekOutput, CalendarWeek } from '@agilox/ui-common';

@Component({
	selector: 'ui-datepicker-calendar',
	templateUrl: './datepicker-calendar.component.html',
	styleUrls: ['./datepicker-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCalendarComponent {
	@Input() minDate: Date | undefined = undefined;
	@Input() maxDate: Date | undefined = undefined;
	@Input({ required: true }) disabled!: boolean;
	@Input() currentSelection: string | null = null;
	@Input() syncedDate: Date | undefined;

	private _currentlyDisplayedMonth: Date = new Date();

	@Input() set currentlyDisplayedMonth(value: Date | null) {
		this._currentlyDisplayedMonth = value ? value : new Date();
		this.setDays();
	}

	get currentlyDisplayedMonth(): Date {
		return this._currentlyDisplayedMonth;
	}

	@Output() dateSelectionChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() monthChange: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	days: CalendarDate[] = [];
	calendarWeeks: CalendarWeek[] = [];
	weekDays: string[] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
	currentlyHovered: CalendarDate | null = null;
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	private setDays(): void {
		this.days = generateMonth(
			this.currentlyDisplayedMonth.getFullYear(),
			this.currentlyDisplayedMonth.getMonth() + 1,
			this.disabled,
			this.minDate,
			this.maxDate
		);
		this.calendarWeeks = groupByCalendarWeek(this.days);
		this.cdRef.markForCheck();
	}

	isDateSelected(day: CalendarDate): boolean {
		if (day.date === this.currentSelection) {
			return true;
		}
		if (this.syncedDate) {
			function resetTime(date: Date | string): Date {
				const tempDate = new Date(date);
				return new Date(tempDate.setHours(0, 0, 0, 0));
			}

			const syncedDateTime = resetTime(this.syncedDate).getTime();
			const dayTimestamp = resetTime(convertToUTCDate(day.date)).getTime();

			return dayTimestamp === syncedDateTime;
		}
		return false;
	}

	isInRange(day: CalendarDate): boolean {
		if (!this.currentSelection || day.disabled || !this.syncedDate) {
			return false;
		}
		const dayTimestamp = convertToUTCDate(new Date(day.date)).getTime();
		const syncedDateTimestamp = new Date(this.syncedDate).getTime();
		const selectedDayTimestamp = new Date(this.currentSelection).getTime();
		const startTime = Math.min(syncedDateTimestamp, selectedDayTimestamp);
		const endTime = Math.max(syncedDateTimestamp, selectedDayTimestamp);

		return dayTimestamp >= startTime && dayTimestamp <= endTime;
	}

	selectDay(day: CalendarDate) {
		if (day.disabled || this.disabled) {
			return;
		}
		if (day.notInActiveMonth) {
			this.monthChange.emit(convertToUTCDate(day.date));
			return;
		}
		this.dateSelectionChanged.emit(day.date);
	}

	hoverDay(day: CalendarDate) {
		if (!day.disabled && !this.disabled) {
			this.currentlyHovered = day;
		}
	}

	unhoverDay() {
		this.currentlyHovered = null;
	}

	public onCalendarWeekClicked(week: CalendarWeek): void {
		// only select the days that are not disabled
		// if all days are disabled we do nothing
		const days = week.days.filter((day) => !day.disabled);
		if (!days.length) {
			return;
		}

		this.calendarWeekClicked.emit({
			start: days[0].date,
			end: days[days.length - 1].date,
		});
	}
}
