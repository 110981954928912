import {
	App,
	AppError,
	AppMenuPoint,
	AppMenuPoints,
	AppNavMenuPointsService,
	ErrorService,
	ErrorType,
	MenuPointService,
	NavVariant,
	Role,
} from '@agilox/common';
import { MenuPoint } from '@agilox/ui-common';
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, shareReplay } from 'rxjs';
import { filter } from 'rxjs/operators'; // declare ga as a function to access the JS code in TS
import { academyMenuPoints } from './general/constants/menupoint.const';
import { AuthenticationService } from './general/services/authentication.service';
import { UserSettingsService } from './general/services/user-settings.service';
// declare ga as a function to access the JS code in TS
declare let gtag: (event: string, name: string, obj: any | Array<any>) => void;

@Component({
	selector: 'agilox-academy-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
	desktopMenuPoints: Array<MenuPoint>;
	mobileMenuPoints: Array<MenuPoint>;
	appNavMenuPoints: Array<AppMenuPoint>;
	activeApp: AppMenuPoint | undefined = AppMenuPoints.find((app) => app.name === 'academy');
	pageName = '';
	error$: Observable<AppError | null> = this.errorService.error$;

	helpOpen: boolean = false;

	private _routerEvents$: Observable<any> = this.router.events.pipe(
		takeUntilDestroyed(),
		shareReplay(1)
	);
	private _navigationEnd$: Observable<NavigationEnd> = this._routerEvents$.pipe(
		filter((event) => event instanceof NavigationEnd)
	);

	/**
	 * Will get triggered when a navigation chunk fails to load because the user is offline
	 * @private
	 */
	private _navigationChunkLoadError$: Observable<NavigationError> = this._routerEvents$.pipe(
		filter(
			(event) =>
				event instanceof NavigationError &&
				event.error['name'] === 'ChunkLoadError' &&
				!navigator.onLine
		)
	);

	constructor(
		public router: Router,
		private menuPointService: MenuPointService,
		private userSettingsService: UserSettingsService,
		private deviceDetectorService: DeviceDetectorService,
		public auth: AuthenticationService,
		private errorService: ErrorService,
		private appNavMenuPointsService: AppNavMenuPointsService
	) {
		this.desktopMenuPoints = [];
		this.mobileMenuPoints = [];
		this.appNavMenuPoints = [];

		this._navigationEnd$.subscribe((event: NavigationEnd) => {
			const subString = '/';
			const end = event.urlAfterRedirects.split(subString, 2).join(subString).length;
			this.pageName = 'p-' + event.urlAfterRedirects.substring(1, end);
		});
		this._navigationChunkLoadError$.subscribe(() => {
			this.errorService.setError({
				application: App.academy,
				type: ErrorType.NO_INTERNET_ERROR,
			});
		});

		this.desktopMenuPoints = this.menuPointService.getMenuPointsByCategory(
			NavVariant.DESKTOP,
			this.userSettingsService.user?.role as Role
		);
		this.mobileMenuPoints = this.menuPointService.getMenuPointsByCategory(
			NavVariant.MOBILE,
			this.userSettingsService.user?.role as Role
		);
		this.appNavMenuPoints = this.appNavMenuPointsService.getAppNavMenuPoints(
			this.userSettingsService.user?.role as Role
		);
	}

	// ios fix
	ngAfterViewInit() {
		if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
			const doc = document.documentElement;
			doc.style.height = `${window.innerHeight}px`;
			doc.style.overflow = 'hidden';
		}
	}

	navCallback(menuPoint: MenuPoint): void {
		if (menuPoint.name === academyMenuPoints.help.name) {
			this.helpOpen = true;
			return;
		}

		if (menuPoint.name === academyMenuPoints.logout.name) {
			this.logout();
			return;
		}
	}

	logout() {
		this.auth.logout();
	}

	protected readonly Role = Role;

	onClose() {
		this.errorService.clearError();
	}

	onCloseHelp() {
		this.helpOpen = false;
	}

	protected readonly ErrorType = ErrorType;
}
