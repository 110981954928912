<div class="ui-app-nav" [class.is-open]="open">
	<div class="ui-app-nav__trigger" (click)="toggle()" data-cy="app-nav" [class.is-open]="open">
		<ui-icon icon="menu_bento" size="l" [type]="open ? 'dark' : navColor"></ui-icon>
		@if (!open) {
			<div class="ui-app-nav__triggerText ui-app-nav__triggerText--{{ navColor }}">
				{{ 'general.apps' | translate }}
			</div>
		}
	</div>
	@if (open) {
		<div class="ui-app-nav__apps">
			<a
				class="ui-app-nav__myApp"
				[href]="myApp.url || '#'"
				[target]="myApp.newTab ? '_blank' : '_self'"
				(mouseenter)="setHoveredApp(myApp)"
				(mouseleave)="setHoveredApp(undefined)"
				[style.background-color]="myApp | appBackground: hoveredApp : activeApp"
			>
				<ng-container>
					<img alt="logo-my" class="ui-app-nav__logoWorld" [src]="myApp.image" />
				</ng-container>
			</a>
			<div class="ui-app-nav__divider">
				<ui-divider></ui-divider>
			</div>
			@for (app of apps; track app.name) {
				<a
					class="ui-app-nav__app"
					[href]="app.url || '#'"
					[target]="app.newTab ? '_blank' : '_self'"
					[attr.data-cy]="'app-nav-' + app.name"
					[style.background-color]="app | appBackground: hoveredApp : activeApp"
					(mouseenter)="setHoveredApp(app)"
					(mouseleave)="setHoveredApp(undefined)"
				>
					<img [alt]="'logo-' + app.name" [src]="app.image" class="ui-app-nav__logo" />
					@if (app.beta) {
						<ui-tag
							[text]="'general.beta' | translate"
							type="dark"
							class="ui-app-nav__betaTag"
						></ui-tag>
					}
				</a>
			}
		</div>
	}
</div>
