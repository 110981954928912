import { MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppMenuPoint } from '@agilox/common';

@Component({
	selector: 'ui-mobile-nav',
	templateUrl: './mobile-nav.component.html',
	styleUrls: ['./mobile-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavComponent {
	@Input() logoPath: string | undefined;
	@Input() items: MenuPoint[] | undefined;
	@Input() navColor: NavColor = NavColors.Dark;
	@Input() apps: Array<AppMenuPoint> = [];
	@Input() activeApp: AppMenuPoint | undefined;
	@Input() appNavVisible: boolean = true;

	/** Needed to handle the routing of the app bento nav */
	@Input() environment: string = 'live';

	openedMenuPoint: MenuPoint | undefined;
	currentUrl: string = '';
	appNavOpen: boolean = false;

	@Output() callbackEvent: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();

	/** Needed to be able to be used as a web component */
	@Output() itemToRouteClicked: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();

	private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
	public router: Router = inject(Router);
	private elementRef: ElementRef = inject(ElementRef);

	constructor() {
		this.currentUrl = this.router.url;
		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter((event: any) => event instanceof NavigationEnd)
			)
			.subscribe((event) => {
				this.currentUrl = event.urlAfterRedirects;
				this.changeDetectorRef.markForCheck();
			});
	}

	onMenuPointClick(menuPoint: MenuPoint) {
		/**
		 * If the menupoint has a callback, emit the callback event
		 * and do not route
		 */
		if (menuPoint.callback) {
			this.callbackEvent.emit(menuPoint);
		} else if (!menuPoint.submenus && menuPoint.url) {
			/**
			 * MenuPoint has no submenus
			 * Route to url
			 * if successful close menu
			 * */
			this.doRouting(menuPoint);
		} else {
			/**
			 * MenuPoint has submenus
			 * if closed: close all other submenus then open submenu
			 * else: close submenu
			 */
			if (this.openedMenuPoint !== menuPoint) {
				this.openedMenuPoint = menuPoint;
				this.appNavOpen = false;
			} else {
				this.closeMenu();
			}
		}
	}

	/**
	 * This component is being used by non-angular apps, and as such have no access to the angular router. This will cause the router to throw an error which cannot be handled by the non-angular app
	 * and therefore the error is caught here, as the routing is done in the non-angular app
	 */
	doRouting(menuPoint: MenuPoint) {
		this.itemToRouteClicked.emit(menuPoint);
		this.router.navigateByUrl(menuPoint.url ?? '').catch((error) => {});
		this.closeMenu();
	}

	closeMenu() {
		this.openedMenuPoint = undefined;
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: any) {
		const target = event.target as HTMLElement;
		const inside = this.elementRef.nativeElement.contains(target);
		if (!inside) {
			this.closeMenu();
		}
	}

	/**
	 * clicks inside the nav
	 * @param event event
	 */
	clickInsideNav(event: Event): void {
		const target = event?.target as HTMLElement;
		// it should not do this if the target or a parent of the target is a a tag
		if (target?.closest('a')) {
			return;
		}
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}

	onAppNavToggled(open: boolean): void {
		this.appNavOpen = open;
		if (open && this.openedMenuPoint) {
			this.closeMenu();
		}
	}
}
