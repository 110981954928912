import { AppMenuPoint, AppMenuPoints, RedirectService } from '@agilox/common';
import { NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	inject,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'ui-app-nav',
	templateUrl: './app-nav.component.html',
	styleUrls: ['./app-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavComponent implements OnInit {
	@Input() set apps(apps: Array<AppMenuPoint> | undefined) {
		if (!apps) {
			apps = AppMenuPoints;
		}

		this.myApp = apps.find((app) => app.name === 'my') ?? AppMenuPoints[0];
		this._apps = apps.filter((app) => app.name !== 'my');
	}
	private _apps: Array<AppMenuPoint> = [];
	get apps(): Array<AppMenuPoint> {
		return this._apps;
	}

	@Input() activeApp: AppMenuPoint | undefined;
	@Input() environment: string = 'live';
	@Input() navColor: NavColor = NavColors.Dark;
	@Input() open: boolean = false;

	myApp: AppMenuPoint = AppMenuPoints[0];

	private redirectService: RedirectService = inject(RedirectService);

	public hoveredApp: AppMenuPoint | undefined;

	toggle() {
		this.open = !this.open;
	}

	@HostListener('window: click', ['$event'])
	@HostListener('window:keydown.escape', ['$event'])
	clickOutside(event?: MouseEvent | KeyboardEvent) {
		const target = event?.target as HTMLElement;
		const hasUiAppNavClass = target?.closest('.ui-app-nav');
		if (!hasUiAppNavClass && this.open) {
			this.open = false;
		}
	}

	setHoveredApp(app: AppMenuPoint | undefined) {
		this.hoveredApp = app;
	}

	ngOnInit() {
		this.setUrls(this.apps);
		this.setUrls([this.myApp]);
	}

	private setUrls(apps: AppMenuPoint[]) {
		apps.forEach((app) => {
			app.url = this.redirectService.getRedirectAppUrl(app.name, this.environment);
		});
	}
}
