import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { map } from 'rxjs';
import { DropdownDirective } from '../../dropdown/directives';
import {
	DateFormat,
	DateFormatParts,
	getDateFormat,
	ISOStringToUserDate,
	isStringValidDate,
	userDateToISO,
	userInputStringToDate,
} from '@agilox/common';
import { CalendarWeekOutput } from '@agilox/ui-common';

@Component({
	selector: 'ui-datepicker-input',
	templateUrl: './datepicker-input.component.html',
	styleUrl: './datepicker-input.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerInputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DatepickerInputComponent),
			multi: true,
		},
	],
})
export class DatepickerInputComponent implements ControlValueAccessor, OnInit {
	@Input() hasError: boolean = false;
	@Input() inputId: string = '';
	@Input() syncedDate: Date | undefined;
	@Input() showWhenValid: boolean = false;
	@Input() value: Date | undefined = undefined;
	@Input() fullWidth: boolean = false;
	@Input() minDate: Date | undefined;
	@Input() maxDate: Date | undefined;
	@Output() iconClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	@Output() calendarOpened: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('input') input: HTMLInputElement | undefined;
	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;

	formControl: FormControl<string | null> = new FormControl<string>('');

	@Input() currentlyDisplayedMonth: Date = new Date();

	/**
	 * This is sadly needed because we have no way of knowing if
	 * the formControl has been marked as touched or not by the parent.
	 * See: https://github.com/angular/angular/issues/45089
	 * @param value
	 */
	@Input() set touched(value: boolean) {
		if (value) {
			this.formControl.markAsTouched();
		} else {
			this.formControl.markAsUntouched();
		}
	}

	private userDateFormat: DateFormat = getDateFormat();

	private _placeholder: string = '';

	get placeholder(): string {
		return this._placeholder;
	}

	@Input() set placeholder(value: string | undefined) {
		if (value) {
			this._placeholder = value;
			return;
		}
		this._placeholder = this.buildPlaceholder();
	}

	focused: boolean = false;

	dropdownOpened: boolean = false;

	ngOnInit() {
		this.formControl.valueChanges
			.pipe(map((val) => this.doMask(val)))
			.subscribe((value: string) => {
				this.formControl.setValue(value, { emitEvent: false });
				if (this.isValid(value)) {
					const isoDate: string = userDateToISO(value);
					this.currentlyDisplayedMonth = new Date(isoDate);
					this.onChange(isoDate);
					this.onTouched();
				} else {
					this.onChange(null);
				}
			});
	}

	private isValid(value: string) {
		return isStringValidDate(value) ? this.isBetweenMinMax(value) : false;
	}

	private setErrors(value: string | null) {
		if (!value) {
			return;
		}
		this.hasError = !this.isValid(value);
	}

	private isBetweenMinMax(date: string): boolean {
		const largerThanMin: boolean = this.minDate
			? userInputStringToDate(date) >= this.minDate
			: true;
		const smallerThanMax: boolean = this.maxDate
			? userInputStringToDate(date) <= this.maxDate
			: true;
		return largerThanMin && smallerThanMax;
	}

	/**
	 * Mask the input value
	 * e.g. input: 1234567890 -> output: 12.34.5678
	 *
	 *
	 * @param value
	 * @private
	 */
	private doMask(value: string | null): string {
		if (!value) {
			return '';
		}

		/**
		 * If the value already has two dots, then the user is
		 * trying to delete a character, so we need to adjust the cursor position
		 * and not add the dots again!
		 */
		const literal = this.userDateFormat.literal;
		if (value.match(new RegExp(`\\${literal}`, 'g'))?.length === 2) {
			return value;
		}

		// remove all non digit characters
		const sanitized = value.replace(/[^\d]/g, '');

		const literalPositions: number[] = [2, 4];
		let result: string = '';
		for (let i = 0; i < sanitized.length; i++) {
			if (literalPositions.includes(i) && !(i > 0 && sanitized[i - 1] === literal)) {
				result += literal;
			}
			result += sanitized[i];
		}
		return result.slice(0, 10);
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(obj: any) {
		const val = obj ? ISOStringToUserDate(obj) : '';
		this.formControl.setValue(val, { emitEvent: false });
	}

	setDisabledState(isDisabled: boolean) {
		if (isDisabled) {
			this.formControl.disable({ emitEvent: false });
		} else {
			this.formControl.enable({ emitEvent: false });
		}
	}

	calendarSelection(date: string) {
		this.formControl.setValue(ISOStringToUserDate(date));
		this.dropdown?.closeDropdown();
	}

	navDateChange(date: Date) {
		this.currentlyDisplayedMonth = date;
	}

	openCalendar() {
		// Wait for the next tick to open the dropdown
		setTimeout(() => {
			this.dropdown?.openDropdown();
			this.calendarOpened.emit();
		});
	}

	closeCalendar() {
		this.dropdown?.closeDropdown();
	}

	onDropdownStateChange(opened: boolean) {
		this.dropdownOpened = opened;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}
		this.setErrors(this.formControl.value);

		return null;
	}

	private buildPlaceholder(): string {
		const getShortForm = (part: string) => {
			switch (part) {
				case DateFormatParts.year:
					return 'yyyy';
				case DateFormatParts.month:
					return 'mm';
				case DateFormatParts.day:
					return 'dd';
				default:
					return '';
			}
		};

		return this.userDateFormat.parts
			.map((part: string) => getShortForm(part))
			.join(this.userDateFormat.literal);
	}
}
