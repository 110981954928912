import { Tags, TagType } from '@agilox/ui-common';
import { NgClass, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { TagComponent } from '../tag/tag.component';
import { AppColorDefinition } from '@agilox/common';

@Component({
	selector: 'ui-box',
	standalone: true,
	imports: [IconModule, TranslateModule, TagComponent, NgStyle, NgClass],
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent implements OnChanges {
	@Input() image: string | undefined;
	@Input() action: boolean = false;
	@Input() disabled: boolean = false;
	@Input() tagText: string | undefined;
	@Input() tagIcon: string | undefined;
	@Input() tagType: TagType = Tags.PrimaryLight;
	@Input() color: AppColorDefinition | undefined;
	@Input() fullSizeImage: boolean = false;

	@Output() boxClicked: EventEmitter<void> = new EventEmitter<void>();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['image'] && this.image) {
			this.image = encodeURI(changes['image'].currentValue);
		}
	}
	onBoxClicked(): void {
		if (!this.disabled && this.action) {
			this.boxClicked.emit();
		}
	}
}
