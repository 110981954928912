import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'ui-divider',
	standalone: true,
	imports: [NgClass],
	templateUrl: './divider.component.html',
	styleUrls: ['./divider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class DividerComponent {
	@Input() type: 'horizontal' | 'vertical' = 'horizontal';
	@Input() color: 'darkGrey' | 'lightGrey' | 'white' | 'primary' = 'lightGrey';
	@Input() noMargin: boolean = false;
}
