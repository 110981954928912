export interface DateFormat {
	parts: DateFormatParts[];
	literal: string;
}

export enum DateFormatParts {
	year = 'year',
	month = 'month',
	day = 'day',
}

/**
 * Transforms a date string in the format 'dd.mm.yyyy' to an ISO string
 * of the format 'yyyy-mm-dd'
 * @param {string} value - The date string to transform
 * @returns {string} - The transformed ISO date string
 */
export function dateStringToISO(value: string): string {
	const d = getDateFormat();

	const day: number = parseInt(value.slice(0, 2), 10);
	const month: number = parseInt(value.slice(3, 5), 10);
	const year: number = parseInt(value.slice(6, 10), 10);

	// Pad day and month to two characters
	const paddedDay = day.toString().padStart(2, '0');
	const paddedMonth = month.toString().padStart(2, '0');

	return `${year}-${paddedMonth}-${paddedDay}`;
}

/**
 * Transforms a date string in the format of the users locale to a Date object
 * @param {string} value - The date string to transform
 * @returns {Date} - The Date object
 */
export function userInputStringToDate(value: string): Date {
	return new Date(userDateToISO(value));
}

/**
 * Transforms a date string in the format of the users locale to an ISO string
 * of the format 'yyyy-mm-dd'
 * @param {string} value - The date string to transform
 * @returns {string} - The transformed ISO date string
 */
export function userDateToISO(value: string): string {
	const d = getDateFormat();
	const parts = value.split(d.literal);

	if (parts.length !== d.parts.length) {
		return '';
	}

	const obj: { [key: string]: string } = {
		[DateFormatParts.year]: '',
		[DateFormatParts.month]: '',
		[DateFormatParts.day]: '',
	};

	// Map each part of the input date to the corresponding part in the format
	d.parts.forEach((part, index) => {
		obj[part] = parts[index];
	});

	// Construct the ISO date
	return `${obj[DateFormatParts.year]}-${obj[DateFormatParts.month]}-${obj[DateFormatParts.day]}`;
}

/**
 * Transforms an ISO date string to a date string in the user's locale format.
 * @param {string} date - The ISO date string to transform (yyyy-mm-dd)
 * @returns {string} - The transformed date string in the user's locale format
 */
export function ISOStringToUserDate(date: string): string {
	if (!date || !/^\d{4}-\d{2}-\d{2}$/.test(date)) {
		return '';
	}

	// Get the user's date format
	const d = getDateFormat();

	// Parse the ISO date
	const obj: { [key: string]: string } = {
		[DateFormatParts.year]: date.slice(0, 4),
		[DateFormatParts.month]: date.slice(5, 7),
		[DateFormatParts.day]: date.slice(8, 10),
	};

	// Construct the user's date format string
	let result = '';
	d.parts.forEach((part) => {
		result += obj[part] + d.literal;
	});

	// Remove the trailing literal
	return result.slice(0, -d.literal.length);
}

export function isStringValidDate(value: string): boolean {
	if (value.length < 10) {
		return false;
	}

	const date: Date = new Date(userInputStringToDate(value));
	return !isNaN(date.getTime());
}

export function timestampStringToDate(timestamp: string): Date {
	return new Date(parseInt(timestamp, 10) * 1000);
}

export function convertToUTCDate(date: string | number | Date): Date {
	const dateObj = new Date(date);
	return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
}

export function addTimezoneOffset(timestamp: number) {
	return timestamp + new Date(timestamp).getTimezoneOffset() * 60000;
}

export function dateToTimestamp(dateValue: Date | string, timeValue: string): number {
	if (!dateValue) {
		return 0;
	}
	let date: Date;

	/**
	 * If the date gets passed via a string
	 * e.g. from the datepicker, we need to convert it to a date object
	 * and fix the timezone offset which might be off by a day
	 */
	if (typeof dateValue === 'string') {
		date = new Date(dateValue);
		dateValue = convertToUTCDate(dateValue);
	} else {
		date = dateValue;
	}

	const time = timeValue || '00:00';
	const [hours, minutes, seconds = 0] = time.split(':').map(Number);

	date.setHours(hours, minutes, seconds, 0); // Set hours, minutes, seconds, milliseconds
	date.setFullYear(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());

	return new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000;
}

export function timeToTimestamp(time: string): number {
	if (!time || time === '00:00') {
		return 0;
	}
	const [hours, minutes] = time.split(':').map(Number);

	// Convert hours and minutes to milliseconds
	const hoursInMilliseconds = hours * 60 * 60 * 1000;
	const minutesInMilliseconds = minutes * 60 * 1000;

	// Add up the total time in milliseconds
	return hoursInMilliseconds + minutesInMilliseconds;
}

export function timestampToTime(timestamp: number, defaultValue = '00:00'): string {
	if (!timestamp || timestamp <= 0) {
		return defaultValue;
	}
	const date = new Date(timestamp);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`;
}

export function timestampToUTCTime(timestamp: number, defaultValue = '00:00'): string {
	if (timestamp <= 0) {
		return defaultValue;
	}
	const date = new Date(timestamp);
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`;
}

/**
 * Takes a date object and returns a string in the format 'yyyy-mm-dd'
 * It will ignore the time part of the date object and the timezone
 * @param {Date} date - The date object to transform
 */
export function dateToString(date: Date) {
	// Extract the year, month, and day from the date object
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
	const day = String(date.getDate()).padStart(2, '0');

	// Return the date in the 'yyyy-mm-dd' format
	return `${year}-${month}-${day}`;
}

export function getDateFormat(): DateFormat {
	const browserLocale = navigator.language.split('-')[0] || 'de';

	const formatter = new Intl.DateTimeFormat(browserLocale, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	const parts = formatter.formatToParts(new Date());
	const literal = parts.find((part) => part.type === 'literal')?.value || '.';
	const p = parts
		.filter((part) => part.type !== 'literal')
		.map((part) => part.type as DateFormatParts);
	return { parts: p, literal };
}
