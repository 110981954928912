import { AppMenuPoint, AppMenuPoints, Role } from '@agilox/common';
import { Injectable } from '@angular/core';

@Injectable()
export class AppNavMenuPointsService {
	getAppNavMenuPoints(role?: Role): Array<AppMenuPoint> {
		if (role) {
			return AppMenuPoints.filter(
				(menuPoint: AppMenuPoint) =>
					!menuPoint.roles.length ||
					menuPoint.roles.some((menuPointRole: string) => menuPointRole === role)
			);
		} else {
			return AppMenuPoints;
		}
	}
}
