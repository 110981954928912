import { Pipe, PipeTransform } from '@angular/core';
import { AppMenuPoint } from '@agilox/common';

@Pipe({
	name: 'appBackground',
	standalone: true,
})
export class AppBackgroundPipe implements PipeTransform {
	transform(
		app: AppMenuPoint,
		hoveredApp: AppMenuPoint | undefined,
		activeApp: AppMenuPoint | undefined
	): string {
		if (hoveredApp === app) {
			return app.color?.backgroundHover ?? '';
		}
		if (activeApp === app) {
			return app.color?.background ?? '';
		}
		return '';
	}
}
