/**
 * Holds all calendar related utility functions
 * This is currently being used by the datepicker component
 * and the monthly-heatmap component
 */
import { CalendarDate, CalendarWeek } from '@agilox/ui-common';
import { dateToString } from '@agilox/common';
/**
 * Generates the days for a specific month
 * Takes care of the offset for the first day of the month
 * and the days of the previous and next month
 * @param year
 * @param month
 * @private
 */
export function generateMonth(
	year: number,
	month: number,
	disabled: boolean,
	minDate: Date | undefined,
	maxDate: Date | undefined
): CalendarDate[] {
	const numberOfDays: number = new Date(year, month, 0).getDate();
	const firstDay: number = new Date(year, month - 1, 1).getDay();
	const offset = firstDay === 0 ? 6 : firstDay - 1;

	const previousMonthDays: number = (7 + offset) % 7;

	const nextMonthDays: number = (7 - new Date(year, month - 1, numberOfDays).getDay()) % 7;

	const previousMonth: number = month === 1 ? 12 : month - 1;
	const previousYear: number = month === 1 ? year - 1 : year;
	const nextMonth: number = month === 12 ? 1 : month + 1;
	const nextYear: number = month === 12 ? year + 1 : year;

	let monthDays: CalendarDate[] = generatePreviousMonth(
		previousMonthDays,
		previousYear,
		previousMonth,
		year,
		month
	);

	// Generate current month days without UTC
	for (let i: number = 1; i <= numberOfDays; i++) {
		const day: Date = new Date(year, month - 1, i);
		monthDays.push({ date: dateToString(day), disabled: false });
	}

	// Generate next month days without UTC
	for (let i: number = 1; i <= nextMonthDays; i++) {
		const day: Date = new Date(nextYear, nextMonth - 1, i);
		monthDays.push({
			date: dateToString(day),
			notInActiveMonth: true,
		});
	}

	return disableDays(monthDays, disabled, minDate, maxDate);
}

export function generatePreviousMonth(
	previousMonthDays: number,
	previousYear: number,
	previousMonth: number,
	year: number,
	month: number
): CalendarDate[] {
	let monthDays: CalendarDate[] = [];

	const lastDayOfPreviousMonth = new Date(year, month - 1, 0).getDate();

	for (let i = 0; i < previousMonthDays; i++) {
		const day: Date = new Date(
			previousYear,
			previousMonth - 1,
			lastDayOfPreviousMonth - previousMonthDays + i + 1
		);
		monthDays.push({
			date: dateToString(day),
			notInActiveMonth: true,
		});
	}
	return monthDays;
}

export function disableDays(
	days: CalendarDate[],
	disabled: boolean,
	minDate: Date | undefined,
	maxDate: Date | undefined
): CalendarDate[] {
	return days.map((day: CalendarDate) => {
		const currentDay = new Date(day.date);

		if (minDate && currentDay < minDate) {
			day.disabled = true;
		}
		if (maxDate && currentDay > maxDate) {
			day.disabled = true;
		}
		if (disabled) {
			day.disabled = true;
		}

		return day;
	});
}

export function groupByCalendarWeek(days: CalendarDate[]): CalendarWeek[] {
	const weekMap = new Map<number, CalendarWeek>();

	days.forEach((day: CalendarDate) => {
		const date = new Date(day.date);
		const week = getISOWeekNumber(date);
		if (!weekMap.has(week)) {
			weekMap.set(week, { days: [], weekNumber: week });
		}
		weekMap.get(week)?.days.push(day);
	});
	return [...weekMap.values()];
}

function getISOWeekNumber(date: Date): number {
	const target = new Date(date.valueOf());
	const dayNum = (target.getUTCDay() + 6) % 7; // adjust to get Monday as the first day of the week
	target.setUTCDate(target.getUTCDate() + 4 - dayNum); // set to nearest Thursday
	const yearStart = new Date(Date.UTC(target.getUTCFullYear(), 0, 1));
	return Math.ceil(((target.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
}
